import { ELocalhostFinancialStatementStatus } from "data/backoffice/financials/types";

export const statusConfig: {
	label: string;
	value: ELocalhostFinancialStatementStatus;
}[] = [
	{
		label: "New",
		value: ELocalhostFinancialStatementStatus.NEW,
	},
	{
		label: "Under review",
		value: ELocalhostFinancialStatementStatus.UNDER_REVIEW,
	},
	{
		label: "Approved",
		value: ELocalhostFinancialStatementStatus.APPROVED,
	},
	{
		label: "Paid",
		value: ELocalhostFinancialStatementStatus.PAID,
	},
	{
		label: "Payment Failed",
		value: ELocalhostFinancialStatementStatus.PAYMENT_FAILED,
	},
	{
		label: "Paid Manually",
		value: ELocalhostFinancialStatementStatus.PAID_MANUALLY,
	},
	{
		label: "Not Payable",
		value: ELocalhostFinancialStatementStatus.NOT_PAYABLE,
	},
];
