import {
	Box,
	Card,
	CardActions,
	CardContent,
	createStyles,
	Grid,
	Icon,
	TextField,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import copy from "copy-to-clipboard";
import { isNil, throttle } from "lodash-es";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import Geocode from "react-geocode";
import styled from "styled-components";

import endMarker from "assets/icons/avatar-location-end-station.svg";
import startMarker from "assets/icons/avatar-location-start-station.svg";

import { ILocation } from "data/types";

import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import GoogleMapsComponent, { MarkerParams, MarkerStyle } from "ui/maps/Map";

import { ELocationKind, EMapModePlaceOfUsage, IExperienceLocation, IMatchProps, IPlaceType } from "./types";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

const autocompleteService = { current: null };

const clipboardLocationObjectIdentifier = "MapMode location data:";

const StyledLabel = styled.span<{ fontWeight: number }>`
	font-weight: ${props => props.fontWeight};
`;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icon: {
			marginRight: theme.spacing(2),
		},
		textarea: {
			paddingTop: theme.spacing(4),
		},
		mapContainer: {
			width: "100%",
		},
	}),
);

export interface IMapMode {
	onChange: (newValue) => void;
	value: IExperienceLocation | null;
	mode?: EMapModePlaceOfUsage;
}

const MapMode = memo(({ onChange, value, mode }: IMapMode) => {
	const [startValue, setStartValue] = useState<ILocation | null>(value ? value.start : null);
	const [endValue, setEndValue] = useState<ILocation | null>(value ? value.end : null);
	const [inputValue, setInputValue] = useState<string>("");
	const [options, setOptions] = useState<ILocation[]>([]);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const { t } = useTranslation();
	const { addError, addSuccess } = useNotification();
	const classes = useStyles({ isSmallScreen });

	const backofficeMode = mode === EMapModePlaceOfUsage.backoffice;

	const getCoordinates = async address => {
		const {
			results: [bestMatch],
		} = await Geocode.fromAddress(address);
		const { lat, lng: lon } = bestMatch.geometry.location;
		return { lat, lon };
	};

	const getAddress = async coords => {
		const {
			results: [bestMatch],
		} = await Geocode.fromLatLng(coords.lat, coords.lon);
		return bestMatch.formatted_address;
	};

	const addSelectedLocationToClipboard = useCallback(
		(event: ClipboardEvent, location: ELocationKind) => {
			try {
				const locationToCopy = location === ELocationKind.start ? startValue : endValue;

				if (!locationToCopy) {
					throw new Error();
				}

				const preparedDataToCopy = JSON.stringify(locationToCopy);

				event.preventDefault();

				copy(`${clipboardLocationObjectIdentifier}${preparedDataToCopy}`);

				addSuccess("EXPERIENCE.FORM.MAP.COPY_LOCATION.SUCCESS", true);
			} catch {
				addError("EXPERIENCE.FORM.MAP.COPY_LOCATION.ERROR", true);
			}
		},
		[startValue, endValue, addError, addSuccess],
	);

	const readLocationFromClipboard = useCallback(
		(event: ClipboardEvent, location: ELocationKind) => {
			try {
				const clipboardText = event.clipboardData?.getData("text");

				if (clipboardText && clipboardText.startsWith(clipboardLocationObjectIdentifier)) {
					event.preventDefault();

					const parsedLocation = JSON.parse(clipboardText.split(clipboardLocationObjectIdentifier)[1]);

					if (parsedLocation) {
						location === ELocationKind.start
							? setStartValue(parsedLocation as ILocation)
							: setEndValue(parsedLocation as ILocation);

						addSuccess("EXPERIENCE.FORM.MAP.PASTE_LOCATION.SUCCESS", true);
					}
				}
			} catch {
				addError("EXPERIENCE.FORM.MAP.PASTE_LOCATION.ERROR", true);
			}
		},
		[setStartValue, setEndValue, addError, addSuccess],
	);

	useEffect(() => {
		const location = {
			start: {
				coordinates: startValue?.coordinates,
				formatted_address: startValue?.formatted_address,
				tips: startValue?.tips,
			},
			end: {
				coordinates: endValue?.coordinates,
				formatted_address: endValue?.formatted_address,
				tips: endValue?.tips,
			},
		};
		onChange(location);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startValue, endValue]);

	useEffect(() => {
		const handleCoordinates = async () => {
			const coords = await getCoordinates(startValue?.formatted_address);
			setStartValue((prevValue: ILocation) => ({
				...prevValue,
				coordinates: coords,
			}));
		};
		const handleAddress = async () => {
			const address = await getAddress(startValue?.coordinates);
			setStartValue((prevValue: ILocation) => ({
				...prevValue,
				formatted_address: address,
			}));
		};
		if (!isNil(startValue) && isNil(startValue.coordinates) && startValue.formatted_address) {
			handleCoordinates();
		} else if (!isNil(startValue) && !isNil(startValue.coordinates) && isNil(startValue.formatted_address)) {
			handleAddress();
		}
	}, [startValue]);

	useEffect(() => {
		const handleCoordinates = async () => {
			const coords = await getCoordinates(endValue?.formatted_address);
			setEndValue((prevValue: ILocation) => ({
				...prevValue,
				coordinates: coords,
			}));
		};
		const handleAddress = async () => {
			const address = await getAddress(endValue?.coordinates);
			setEndValue((prevValue: ILocation) => ({
				...prevValue,
				formatted_address: address,
			}));
		};
		if (!isNil(endValue) && isNil(endValue.coordinates) && endValue.formatted_address) {
			handleCoordinates();
		} else if (!isNil(endValue) && !isNil(endValue.coordinates) && isNil(endValue.formatted_address)) {
			handleAddress();
		}
	}, [endValue]);

	const fetch = useMemo(
		() =>
			throttle((request: { input: string }, callback: (results?: IPlaceType[]) => void) => {
				(autocompleteService.current as any).getPlacePredictions(request, callback);
			}, 200),
		[],
	);

	useEffect(() => {
		let active = true;

		if (!autocompleteService.current && (window as any).google) {
			autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
		}

		if (inputValue === "") {
			setOptions([]);
			return undefined;
		}

		fetch({ input: inputValue }, (results?: IPlaceType[]) => {
			if (active) {
				let newOptions = [] as ILocation[];

				if (results) {
					newOptions = results.map(({ description, ...rest }) => ({ ...rest, formatted_address: description }));
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [inputValue, fetch]);

	const renderOption = option => {
		const matches = option.structured_formatting.main_text_matched_substrings;

		const parts = parse(
			option.structured_formatting.main_text,
			matches?.map((match: IMatchProps) => [match.offset, match.offset + match.length]) || [],
		);

		return (
			<Grid container alignItems="center">
				<Grid item>
					<LocationOnIcon className={classes.icon} />
				</Grid>

				<Grid item xs>
					{parts.map((part, index) => (
						<StyledLabel key={index} fontWeight={part.highlight ? 700 : 400}>
							{part.text}
						</StyledLabel>
					))}

					<Typography variant="body2" color="textSecondary">
						{option.structured_formatting.secondary_text}
					</Typography>
				</Grid>
			</Grid>
		);
	};

	const renderCard = (position: ELocationKind, positionValue, setPositionValue) => (
		<Card variant="outlined">
			<CardContent>
				<Autocomplete
					id="google-map-demo"
					getOptionLabel={option => option.formatted_address || ""}
					options={options}
					autoComplete
					includeInputInList
					filterSelectedOptions
					value={positionValue}
					onChange={(event: any, newValue: IPlaceType | null) => {
						setPositionValue(newValue);
					}}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					renderInput={params => (
						<Box display="flex" flexDirection="row">
							<Box flexGrow={0}>
								<Icon className={classes.icon} fontSize="large">
									<img alt="" src={position === ELocationKind.end ? endMarker : startMarker} />
								</Icon>
							</Box>
							<Box flexGrow={1}>
								<TextField
									{...params}
									label={t(`EXPERIENCE.FORM.MAP.${position}.PLACEHOLDER`)}
									fullWidth
									inputProps={{
										...params.inputProps,
										onCopy: backofficeMode
											? e => addSelectedLocationToClipboard(e as unknown as ClipboardEvent, position)
											: undefined,
										onPaste: backofficeMode
											? e => readLocationFromClipboard(e as unknown as ClipboardEvent, position)
											: undefined,
									}}
								/>
							</Box>
						</Box>
					)}
					renderOption={renderOption}
				/>

				<CardActions>
					<Box display="flex" flexDirection="column" width="100%">
						<Typography variant="subtitle2" component="span" color="inherit">
							{t(`EXPERIENCE.FORM.MAP.${position}.TEXTAREA_TIP`)}
						</Typography>
						<TextField
							value={!isNil(positionValue) ? positionValue.tips : ""}
							onChange={e => {
								e.persist();
								setPositionValue(prev => ({ ...prev, tips: e.target.value }));
							}}
							className={classes.textarea}
							multiline
							rows={2}
							placeholder={t(`EXPERIENCE.FORM.MAP.${position}.TEXTAREA_PLACEHOLDER`)}
						/>
					</Box>
				</CardActions>
			</CardContent>
		</Card>
	);

	const markers = useMemo(() => {
		const newMarkers: MarkerParams[] = [];

		// For initial value only, prevent displaying markers with initial value
		if (
			startValue &&
			!isNil(startValue?.coordinates) &&
			endValue &&
			!isNil(endValue?.coordinates) &&
			startValue.coordinates.lat === 0 &&
			startValue.coordinates.lon === 0 &&
			endValue.coordinates.lat === 0 &&
			endValue.coordinates.lon === 0
		) {
			return newMarkers;
		}

		if (startValue && !isNil(startValue?.coordinates)) {
			newMarkers.push({
				lat: startValue.coordinates.lat,
				lng: startValue.coordinates.lon,
				markerStyle: MarkerStyle.start,
			});
		}

		if (endValue && !isNil(endValue?.coordinates)) {
			newMarkers.push({
				lat: endValue.coordinates.lat,
				lng: endValue.coordinates.lon,
				markerStyle: MarkerStyle.end,
			});
		}
		return newMarkers;
	}, [startValue, endValue]);

	const handleMapClick = e => {
		const newCoords = { lat: e.lat, lon: e.lng };

		if (!startValue?.coordinates) {
			setStartValue(prev => ({
				tips: prev?.tips,
				coordinates: newCoords,
			}));
		} else if (!endValue?.coordinates) {
			setEndValue(prev => ({
				tips: prev?.tips,
				coordinates: newCoords,
			}));
		}
	};

	return (
		<>
			<Box display="flex" flexDirection={isSmallScreen ? "column" : "row"}>
				<Box display="flex" flexDirection="column" pr={isSmallScreen ? 0 : 10} pb={isSmallScreen ? 6 : 0}>
					<Box>{renderCard(ELocationKind.start, startValue, setStartValue)}</Box>
					<Box pt={6}>{renderCard(ELocationKind.end, endValue, setEndValue)}</Box>
				</Box>

				<Box display="flex" flexDirection="column" className={classes.mapContainer}>
					<GoogleMapsComponent
						fixedHeight={390}
						markers={markers}
						center={markers.length ? { lat: markers[0].lat, lng: markers[0].lng } : undefined}
						dynamicCenter={markers.length ? { lat: markers[0].lat, lng: markers[0].lng } : undefined}
						getLocation={handleMapClick}
						zoom={5}
					/>
				</Box>
			</Box>
		</>
	);
});

export default MapMode;
