import { Box } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";

import {
	ELocalhostFinancialStatementStatus,
	ILocalhostFinancialStatement,
	TStatus,
} from "data/backoffice/financials/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { MY_FINANCES_TABS, paths } from "routing/paths";

import financialsServices from "services/api/financials";

import { getDateWithDefaultTime, getMonthName } from "utils/dates";
import { prices } from "utils/prices";
import fillRoute from "utils/routes";

import FilterSearchPastEarnings, { EType, ISelectedFilters } from "components/finances/FilterSearchPastEarnings";
import { statusConfig } from "components/finances/FilterSearchPastEarnings/config";

import ChildrenLoader from "ui/loaders/ChildrenLoader";
import Table, { Order } from "ui/Table";
import { Tooltip } from "ui/tooltips/Tooltip";

import { StyledLink } from "styles/common";

import { columns, fields, sortedColumns } from "./config";

interface IQueryParams {
	min_created_on?: string;
	max_created_on?: string;
	min_earnings?: string;
	max_earnings?: string;
	status?: TStatus;
}

const Withdrawals = () => {
	const { t, translateArray } = useTranslation();

	const { handleAndNotify } = useErrors();

	const [results, setResults] = useState<ILocalhostFinancialStatement[]>();
	const [orderData, setOrderData] = useState<{ sort: string; order: Order; orderBy: number }>();
	const [queryParams, setQueryParams] = useState<IQueryParams>();

	const fetchWithdrawals = useCallback(
		async (data?: object, reset?: boolean) => {
			try {
				const financials = await financialsServices.getSearchLocalhostFinancialStatements({
					...(orderData && !reset && { sort: orderData.sort, order: orderData.order }),
					...(!reset && queryParams),
					...data,
					limit: 100,
				});

				setResults(financials.data);
			} catch (e) {
				handleAndNotify(e);
			}
		},

		// eslint-disable-next-line
		[orderData, queryParams],
	);

	useEffect(() => {
		fetchWithdrawals();

		// eslint-disable-next-line
	}, []);

	const sortData = (nameField: string, order: Order, orderBy: number) => {
		setResults(undefined);

		setOrderData({ sort: nameField, order, orderBy });
		fetchWithdrawals({ sort: nameField, order });
	};

	const getFilters = (filters: ISelectedFilters) => {
		const queryParamsFromFilters: IQueryParams = {};

		Object.entries(filters).forEach(([filterType, filterValue]) => {
			if (!filterValue) {
				return;
			}

			if (filterType === "dates") {
				if (filterValue.start) {
					queryParamsFromFilters.min_created_on = getDateWithDefaultTime(filterValue.start, "FROM");
				}
				if (filterValue.end) {
					queryParamsFromFilters.max_created_on = getDateWithDefaultTime(filterValue.end, "TO");
				}
			} else if (filterType === "price") {
				if (filterValue.from) {
					queryParamsFromFilters.min_earnings = filterValue.from;
				}
				if (filterValue.to) {
					queryParamsFromFilters.max_earnings = filterValue.to;
				}
			} else if (filterType === "status") {
				queryParamsFromFilters.status = filterValue;
			}
		});

		setOrderData(undefined);
		setResults(undefined);

		setQueryParams(queryParamsFromFilters);
		fetchWithdrawals(queryParamsFromFilters, true);
	};

	const parsedData =
		results?.map(elem => {
			const statusVal = statusConfig.find(status => status.value === elem.status)?.label || elem.status;
			return {
				...elem,
				month: getMonthName(elem.month),
				earnings: <strong>{prices(elem.earnings, elem.currency)}</strong>,
				reimbursement: !!elem.reimbursement && <strong>{prices(elem.reimbursement, elem.currency)}</strong>,
				status: (
					<>
						{elem.status === ELocalhostFinancialStatementStatus.NOT_PAYABLE ? (
							<Box display="flex" alignItems="center">
								{statusVal}
								<Tooltip>{elem.comment || t("MY_FINANCES.WITHDRAWALS.TABLE.NO_COMMENT_AVAILABLE")}</Tooltip>
							</Box>
						) : (
							<div>{statusVal}</div>
						)}

						{elem.status === ELocalhostFinancialStatementStatus.PAYMENT_FAILED && (
							<small>
								<strong>
									(
									<StyledLink to={fillRoute(paths.MY_FINANCES, { page: MY_FINANCES_TABS.BANKING_DETAILS })}>
										{t("MY_FINANCES.WITHDRAWALS.TABLE.BANKING_DETAILS_ALERT")}
									</StyledLink>
									)
								</strong>
							</small>
						)}
					</>
				),
			};
		}) || [];

	return (
		<>
			<Box marginBottom="20px">
				<FilterSearchPastEarnings applySelectedFiltersAction={getFilters} type={EType.WITHDRAWALS} />
			</Box>

			<Box style={{ overflowX: "auto" }}>
				<ChildrenLoader check={results}>
					<Table
						data={parsedData}
						columns={translateArray(columns)}
						fields={fields}
						sortedColumns={translateArray(sortedColumns)}
						externalSort={sortData}
						initialOrder={orderData?.order}
						initialOrderBy={orderData?.orderBy}
					/>
				</ChildrenLoader>
			</Box>
		</>
	);
};

export default Withdrawals;
