import { ILocation } from "data/types";

export enum ELocationKind {
	start = "start",
	end = "end",
}

export enum EMapModePlaceOfUsage {
	backoffice = "backoffice",
}

export interface IExperienceLocation {
	[ELocationKind.start]: ILocation;
	[ELocationKind.end]: ILocation;
}

export interface IMatchProps {
	offset: number;
	length: number;
}

export interface IPlaceType extends ILocation {
	description: string;
	structured_formatting?: {
		main_text: string;
		secondary_text: string;
		main_text_matched_substrings: IMatchProps[];
	};
}
